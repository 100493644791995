export default {
  methods: {
    generate_perf_net(type) {
      const benchmarks = this.data.share.pdf_benchmarks.map(b => b.label).sort()
      const n = Math.max(
        this.data.share.analytics.cumulated_net?.length,
        this.data.share.analytics.annualised_net?.length,
      )
      let arr
      if (type === 'cumulated') arr = this.data.share.analytics.cumulated_net
      if (type === 'annualized') arr = this.data.share.analytics.annualised_net
      const headers = [type, this.data.share.fund_or_mandat, ...benchmarks]
      const data = [headers].concat(
        arr.map(v =>
          [v.period].concat(['fund', ...benchmarks].map((h, i) => (v[h] === null ? '-' : format('.2%')(v[h])))),
        ),
      )
      if (arr.length < n && !$root.lang.includes('be')) data.splice(1, 0, ...Array(n - arr.length).fill([]))
      const transpose = arr => arr[0].map((_, c) => arr.map(r => r[c]))
      return transpose(data)
    },
  },
  computed: {
    key_figures() {
      const aum = new Intl.NumberFormat($root.lang.slice(0, 2), {
        style: 'currency',
        currency: this.data.share.characteristics.share_currency,
        notation: 'compact',
        compactDisplay: 'short',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .format(+this.data.share.analytics.aum_fund_share_currency)
        .replace(/\s/g, '')

      return [
        ['key', 'value'],
        [
          'nav',
          format(',.2f' + (this.data.share.share_currency || '€'))(this.data.share.analytics.net_asset_value) || 'NA',
        ],
        ['aum', aum || 'NA'],
      ]
    },
    cumulated_net() {
      return this.generate_perf_net('cumulated')
    },
    annualized_net() {
      return this.generate_perf_net('annualized')
    },
    historical_table() {
      const period = this.data.share.analytics.monthly_performances
        .map(d => d.month.split('-')[0])
        .unique()
        .sort(d => -d)
        .slice(0, 5)
      const perf = (year, metric) =>
        Array(12)
          .fill()
          .map((_, i) => ('0' + (i + 1)).slice(-2))
          .map(month =>
            format('.2%')(
              (this.data.share.analytics.monthly_performances.find(el => el.month === year + '-' + month) || {})[
                metric
              ],
            ),
          )
      return period.reduce(
        (acc, year) => {
          ;['fund', this.data.share.benchmark?.label] //! to check with cilent
            .flat()
            .filter()
            .forEach(metric => {
              acc.push(
                [
                  metric === 'fund' ? year : '',
                  metric === 'fund' ? this.data.share.fund_or_mandat : metric,
                  perf(year, metric),
                  format('.2%')(
                    (this.data.share.analytics.yearly_performances.find(v => v.year === year) || {})[metric] ||
                      format('.2%')(0),
                  ),
                ].flat(),
              )
            })
          return acc
        },
        [['', ' ', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year']],
      )
    },
    yearly_asof() {
      // final format: if BE: x,x% ; if not BE: x,xx
      let yearly_perf = this.data.share.analytics.yearly_performances
        .reduce((acc, v) => {
          acc[v.year] = v
          return acc
        }, {})
        .filter((v, k) => k <= this.perf_domain[1]) //! should be done on date not year
        .map(d =>
          d[this.data.share.benchmark?.label]
            ? {
                fund: d.fund * 100,
                benchmark: d[this.data.share.benchmark?.label] * 100,
              }
            : { fund: d.fund * 100 },
        )
      const zeroObj =
        Object.keys(yearly_perf[Object.keys(yearly_perf)[0]]).length === 1
          ? { fund: '-' }
          : { fund: '-', benchmark: '-' }
      let list_date = Object.keys(yearly_perf)
        .sort(d => -d)
        .slice(0, 10)
      const options = {
        format: '.2f',
        variant: 'clustered-bar',
        yAxisSize: 0,
        labelsEnabled: true,
        valueFormat: '.2f',
      }
      if ($root.lang.includes('be')) {
        yearly_perf = yearly_perf.map(el => {
          const res = { fund: el.fund / 100 }
          if (el.benchmark) res.benchmark = el.benchmark / 100
          return res
        })
        if (new Date(this.data.share.performanceDates[0]) > new Date(`${list_date[list_date.length - 1]}-01-01`)) {
          // if first year is not complete, delete first year
          delete yearly_perf[list_date[list_date.length - 1]]
          list_date.shift()
        }

        let end = parseInt(this.perf_domain[1].slice(0, 4))
        if (new Date(this.perf_domain[1]).getMonth() === 12 && new Date(this.perf_domain[1]).getDate() > 27) {
          end += 1
        }
        list_date = [...Array(end).keys()].slice(-5)
        options.format = '.1%'
        options.valueFormat = '.1%'
        delete options.yAxisSize
      }

      const data = list_date.reduce((acc, d) => {
        acc[d] = yearly_perf[d] || zeroObj
        return acc
      }, {})
      return {
        title: 'performance_historical_yearly',
        data,
        options,
      }
    },
    historical_asof_filtered() {
      let d0 = this.data.share.characteristics.start_date_performance
        ? this.parse_date_param(this.data.share.characteristics.start_date_performance)
        : this.data.share.performanceDates[0]
      if ($root.lang.includes('be')) {
        d0 = this.data.share.characteristics.start_date_performance_be
          ? this.parse_date_param(this.data.share.characteristics.start_date_performance_be)
          : this.data.share.performanceDates[0]
      }
      const perf_historical = this.data.share.analytics.historical.reduce((acc, v) => {
        acc[v.date] = v
        return acc
      }, {})
      const vls = perf_historical.filter((v, k) => k >= d0 && k <= this.perf_domain[1])
      const first = vls.v()[0]
      const keys = ['fund'].concat(this.data.share.pdf_benchmarks.map('label'))
      const data = vls.reduce((acc, v, k) => {
        acc[k] = v
          .filter((v1, k1) => keys.includes(k1))
          .map((vl, metric) => ($root.lang.includes('be') ? (+vl / first[metric]) * 100 : +vl / first[metric] - 1))
        if (this.data.share.fund_or_mandat !== 'fund') {
          acc[k][this.data.share.fund_or_mandat] = acc[k].fund
          delete acc[k].fund
        }
        return acc
      }, {})
      const options = {
        format: $root.lang.includes('be') ? '.0f' : '.0%',
      }
      const days_number = Math.round(
        (new Date(data.keys().last()).getTime() - new Date(data.keys().first()).getTime()) / (1000 * 3600 * 24),
      )
      if (days_number < 40) {
        options.format_xAxis = {
          interval: 'day',
          step: Math.round(days_number / 10),
          formatter: "d=>d.format('DD/MM')",
        }
      }
      return {
        title: [
          this.data.share.characteristics.mandat_is_perf_net ||
          this.data.share.characteristics.mandat_is_perf_net === ''
            ? 'performance_evolution'
            : 'performance_brute_evolution',
          this.data.share.characteristics.start_date_performance?.replaceAll('/', '_')?.replaceAll(' ', '_'),
        ]
          .filter(s => s)
          .join('_'),
        data,
        options,
      }
    },
  },
}
