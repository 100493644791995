import {
  IGridCell,
  IGridItemMetadata,
  IGridNode,
  IGridState,
  INxGridItemProps,
  IThemeClass,
  IThemeConfig,
  IThemeFunction,
  ITypeThemeBase,
} from '@hauru/common'
import { extend } from '../nx-theme/nx-theme'

export interface IGridCheckbox {
  idle?: IThemeClass<INxGridItemProps>
  checked?: IThemeClass<INxGridItemProps>
  anyChecked?: IThemeClass<INxGridItemProps>
}

export interface IGridItemTheme {
  container?: IThemeClass<INxGridItemProps>
  radius?: IThemeFunction<INxGridItemProps, number | number[]>
  buttons?: {
    container?: IThemeClass<INxGridItemProps>
    fader?: IThemeClass<INxGridItemProps>
    index?: IThemeClass<INxGridItemProps>
    checkbox?: IGridCheckbox
  }
}

type INumberOrFuntion = number | ((node: IGridNode) => number)

export interface IGridTheme extends ITypeThemeBase {
  container?: IThemeClass
  freezed?: {
    initial: IThemeClass
    scrolled: IThemeClass
  }
  cell?: {
    container?: IThemeClass<INxGridItemProps & IGridCell>
    content?: IThemeClass<INxGridItemProps & IGridCell>
  }
  node?: IGridItemTheme & {
    /**
     * The height of each node header in px (collapsed or not)
     */
    nodeHeaderHeight?: number
    /**
     * The gap between rows in px
     */
    rowGap?: number
    /**
     * The gap between nodes in px
     */
    nodeGap?: number
    /**
     * The inner offset of each node in px
     * Either a number or an array of 4 numbers (top, right, bottom, left)
     */
    nodeOffset?:
      | number
      | [INumberOrFuntion, INumberOrFuntion, INumberOrFuntion, INumberOrFuntion]
      | ((node: IGridNode) => number | [INumberOrFuntion, INumberOrFuntion, INumberOrFuntion, INumberOrFuntion])
    /**
     * The inner offset of the grid container in px
     * Either a number or an array of 4 numbers (top, right, bottom, left)
     */
    containerOffset?: number | [number, number, number, number]
  }
  row?: IGridItemTheme & {
    /**
     * The height of each row in px
     */
    rowHeight?: number
    rowLeftOffset?: IThemeFunction<
      {
        state: IGridState
        level: number
        parentNode: IGridNode | null
        metadataNode: IGridItemMetadata | null
      },
      number
    >
  }
  resizer?: {
    container?: IThemeClass
    content?: IThemeClass
  }
  actions?: {
    find?: {
      placeholder: string
      idle: IThemeClass
      active?: IThemeClass
    }
  }
  header?: {
    checkbox?: IGridCheckbox
    container?: IThemeClass
    column?: {
      container?: IThemeClass
      content?: IThemeClass
      idle?: IThemeClass
      selected?: IThemeClass
      sorted?: IThemeClass
      sortOrder?: IThemeClass
    }
    dropdown?: {
      container?: IThemeClass
      content?: IThemeClass
    }
  }
  icons: {
    sortAscending: IThemeClass
    sortDescending: IThemeClass
    lineDensitySmall: IThemeClass
    lineDensityLarge: IThemeClass
    textSmall: IThemeClass
    textMedium: IThemeClass
    textLarge: IThemeClass
    columnSortAscending: IThemeClass
    columnSortDescending: IThemeClass
    columnExpand: IThemeClass
    columnCollapse: IThemeClass
    columnGroup: IThemeClass
    columnUngroup: IThemeClass
    columnFreeze: IThemeClass
    columnUnfreeze: IThemeClass
    columnFreezeUpTo: IThemeClass
    columnUnfreezeUpTo: IThemeClass
    columnHide: IThemeClass
  }
  labels: {
    hideColumns: string
    hiddenColumn: string
    hiddenColumns: string
    group: string
    groups: string
    sort: string
    sorts: string
    columnSortAscending: string
    columnSortDescending: string
    columnGroup: string
    columnUngroup: string
    columnFreeze: string
    columnUnfreeze: string
    columnFreezeUpTo: string
    columnUnfreezeUpTo: string
    columnHide: string
  }
  selected: {
    checkbox?: IGridCheckbox
    container: {
      widthOffset?: number
      heightOffset?: number
      cell?: IThemeClass
    }
    cell?: IThemeClass
    range?: IThemeClass
    handle?: IThemeClass
    row?: IThemeClass
    node?: IThemeClass
  }
  plot: {
    container?: IThemeClass
  }
}

export const gridZIndex = {
  content: 'z-10',
  surround: 'z-20',
  aboveSurround: 'z-30',
  freezed: 'z-[40]',
  header: 'z-[50]',
  resizer: 'z-[60]',
  actions: 'z-[100]',
}

export const gridTheme: IThemeConfig = {
  themes: {
    $default: {
      grid: {
        basic: {
          node: {
            nodeHeaderHeight: 18,
            rowGap: 0,
            nodeGap: 6,
            nodeOffset: 16,
            containerOffset: 16,
          },
          row: {
            rowLeftOffset: 32,
          },
          labels: {
            hideColumns: 'Hide columns',
            hiddenColumn: 'Hidden column',
            hiddenColumns: 'Hidden columns',
            group: 'Group',
            groups: 'Groups',
            sort: 'Sort',
            sorts: 'Sorts',
            columnSortAscending: 'Add to sorted columns A → Z',
            columnSortDescending: 'Add to sorted columns Z → A',
            columnGroup: 'Group by this column',
            columnUngroup: "Don't group by this column",
            columnFreeze: 'Freeze this column',
            columnUnfreeze: 'Unfreeze this column',
            columnFreezeUpTo: 'Freeze up to this column',
            columnUnfreezeUpTo: 'Unfreeze up to this column',
            columnHide: 'Hide column',
          },
        },
      },
      dropdown: {
        grid: {
          basic: {},
          actions: {
            basic: {
              $extends: '../basic',
            },
            hide: {
              $extends: './basic',
            },
            group: {
              $extends: './basic',
            },
            sort: {
              $extends: './basic',
            },
            filter: {
              $extends: './basic',
            },
            pivot: {
              $extends: './basic',
            },
            transpose: {
              $extends: './basic',
            },
            plot: {
              $extends: './basic',
            },
            density: {
              $extends: './basic',
            },
          },
          header: {
            $extends: './basic',
          },
        },
      },
      button: {
        grid: {
          actions: {
            basic: {},
            hide: {
              $extends: './basic',
            },
            group: {
              $extends: './basic',
            },
            sort: {
              $extends: './basic',
            },
            filter: {
              $extends: './basic',
            },
            pivot: {
              $extends: './basic',
              label: 'Pivot',
            },
            transpose: {
              $extends: './basic',
              label: 'Transpose',
            },
            plot: {
              $extends: './basic',
              label: 'Chart',
            },
            density: {
              $extends: './basic',
            },
          },
          subactions: {
            basic: {},
            hideAll: {
              $extends: './basic',
              label: 'Hide all',
            },
            showAll: {
              $extends: './basic',
              label: 'Show all',
            },
            collapseAll: {
              $extends: './basic',
              label: 'Collapse all',
            },
            expandAll: {
              $extends: './basic',
              label: 'Expand all',
            },
            expandToLevel: {
              $extends: './basic',
            },
            removeGroups: {
              $extends: './basic',
              label: 'Remove all groups',
            },
            removeSorts: {
              $extends: './basic',
              label: 'Remove all sorts',
            },
            emptyShowAll: {
              $extends: './basic',
              label: 'Show all columns',
            },
            emptyClearSearch: {
              $extends: './basic',
              label: 'Clear search',
            },
          },
          header: {},
        },
      },
    },
    nx: {
      grid: {
        basic: {
          container: '@container select-none focus:outline-none',
          header: {
            container: 'bg-white',
            checkbox: {
              $extends: '../selected/checkbox',
              idle: extend('group-hover:opacity-100 my-auto mr-2'),
            },
            column: {
              container: 'cursor-pointer select-none !overflow-visible truncate',
              idle: 'font-semibold pl-2 pr-1 py-1 border-gray-200 bg-primary-50 hover:bg-gray-100 transition-colors',
              selected: '!bg-gray-100',
              sorted: 'pr-4',
              content: 'truncate',
              sortOrder:
                'text-xs text-gray-500 h-5 items-center absolute right-[25px] flex p-[2px] pr-1 rounded-full transition-colors',
            },
            dropdown: {
              container: 'max-h-80 overflow-hidden',
              content: 'gap-2 py-1',
            },
          },
          actions: {
            find: {
              placeholder: 'Search',
              idle: 'h-[28px] w-[200px] min-w-[100px]',
            },
          },
          freezed: {
            initial: 'w-px bg-gray-200',
            scrolled: 'shadow-[4px_0_theme(colors.gray.500/15%)]',
          },
          cell: {
            container: [
              'flex h-full flex-shrink-0 flex-grow-0 items-center bg-inherit relative',
              gridZIndex.content,
              _props => {
                if (_props.state.display === 'transpose' && _props.header.label === 'k')
                  return 'bg-primary-50 font-medium'
                if (_props.state.metadata?.matches?.[_props.view.node.id]?.[_props.header?.label])
                  return 'bg-yellow-200'
                if (!_props.state.columns.groupBy.list.length) return
                if (_props.header.index === 0 && _props.view.node.type === 'node') return 'pl-6'
                if (_props.header.index < _props.state.freezedColumnsCount && _props.view.node.type === 'node')
                  return '!flex-shrink !p-0'
              },
              _props => {
                if (_props.header.index === 0 && _props.view.node.type === 'node') return
                return '[contain:strict]'
              },
              _props => [
                _props.view.node.type === 'node'
                  ? _props.state.display === 'pivot'
                    ? ''
                    : ''
                  : 'border-r border-solid border-r-gray-200 last-of-type:border-r-0',
              ],
            ],
            content: 'flex items-center truncate px-2',
          },
          node: {
            container: [
              _props => {
                const max = _props.state.columns.groupBy.list.length
                if (max === 1) return 'bg-primary-50'
                const level = _props.view.node.level
                return level % 2 === 0 ? 'bg-primary-50' : 'bg-primary-100'
              },
              _props => {
                if (
                  _props.view.node.level === 0 ||
                  (_props.view.node.level === 1 && _props.view.node.parent?.is_virtual)
                )
                  return
                return 'rounded'
              },
              'font-medium group/node',
            ],
            buttons: {
              fader: '-mr-1 h-full w-1 shrink-0 grow-0 bg-gradient-to-r',
              checkbox: {
                $extends: '../../selected/checkbox',
                idle: extend('group-hover/node:opacity-100'),
              },
            },
            nodeHeaderHeight: 32,
            rowGap: 0,
            nodeOffset: [
              32,
              0,
              (node: IGridNode) => ((node.rows?.length ?? 0) > 0 ? 0 : 6),
              (node: IGridNode) => ((node.rows?.length ?? 0) > 0 ? 0 : 12),
            ],
            containerOffset: [0, 0, 0, 0],
          },
          row: {
            rowLeftOffset: ({ state }) => (state.display === 'pivot' || !state.showCheckbox ? 0 : 22),
            container: [
              'text-gray-900 hover:bg-primary-100 group/row',
              _props => {
                return _props.view.rowIndex! % 2 === 0 ? 'bg-white' : 'bg-gray-50'
              },
              _props => [
                _props.view.rowIndex === (_props.view.node.parent?.rows?.length ?? 0) - 1 ? '!border-b' : '',
                _props.view.rowIndex === 0 &&
                _props.view.node.parent?.level === 0 &&
                _props.state.display !== 'transpose'
                  ? '!border-t-0'
                  : '',
              ],
            ],
            buttons: {
              index:
                'text-gray-500 text-xs font-medium flex-shrink-0 flex-grow-0 ml-2 group-hover/row:opacity-0 opacity-100 min-w-[18px]',
              checkbox: {
                $extends: '../../selected/checkbox',
                idle: extend('absolute left-1 group-hover/row:opacity-100'),
              },
            },
          },
          resizer: {
            container: [
              'group/resizer absolute flex content-center w-[9px] top-0 bottom-0 -right-[3px] translate-x-[1px] cursor-ew-resize',
              gridZIndex.resizer,
            ],
            content: 'group-hover/resizer:opacity-100 opacity-0 bg-[#3a714a] w-[3px] mt-1 mb-[3px] m-auto rounded-full',
          },
          selected: {
            container: {
              widthOffset: 1,
              heightOffset: 1,
              cell: 'border-2 border-solid border-[#3a714a] -mt-px',
            },
            cell: '',
            range: '!bg-primary-200',
            row: '!bg-primary-100 !border-primary-300',
            node: 'border border-solid !border-primary-500',
            handle:
              'absolute -bottom-[6px] -right-[6px] cursor-crosshair border border-solid border-white w-[10px] h-[10px] bg-[#3a714a] rounded-full',
            checkbox: {
              idle: 'opacity-0 w-4 h-4 text-primary bg-white border border-solid border-primary-300 rounded shrink-0',
              anyChecked: '!opacity-100',
            },
          },
          plot: {
            container: 'flex-1 bg-white p-4',
          },
          labels: {
            hideColumns: 'Hide columns',
            hiddenColumn: 'Hidden column',
            hiddenColumns: 'Hidden columns',
            group: 'Group',
            groups: 'Groups',
            sort: 'Sort',
            sorts: 'Sorts',
            columnSortAscending: 'Add to sorted columns A → Z',
            columnSortDescending: 'Add to sorted columns Z → A',
            columnGroup: 'Group by this column',
            columnUngroup: "Don't group by this column",
            columnFreeze: 'Freeze this column',
            columnUnfreeze: 'Unfreeze this column',
            columnFreezeUpTo: 'Freeze up to this column',
            columnUnfreezeUpTo: 'Unfreeze up to this column',
            columnHide: 'Hide column',
          },
          icons: {
            sortAscending: 'i-[mdi/sort-descending] !h-4 text-gray-500',
            sortDescending: 'i-[mdi/sort-ascending] !h-4 text-gray-500',
            lineDensitySmall: 'i-[ic/round-density-small] !h-4',
            lineDensityLarge: 'i-[ic/round-density-medium] !h-4',
            textSmall: 'i-[fe/text-size] !h-3',
            textMedium: 'i-[fe/text-size] !h-4',
            textLarge: 'i-[fe/text-size] !h-5',
            columnSortAscending: 'i-[mdi/sort-descending]',
            columnSortDescending: 'i-[mdi/sort-ascending]',
            columnExpand: 'i-[ic/baseline-add]',
            columnCollapse: 'i-[ic/baseline-remove]',
            columnGroup: 'i-[mdi/list-box-outline] !h-4',
            columnUngroup: 'i-[mdi/list-box-outline] !h-4',
            columnFreeze: 'i-[mdi/table-star] !h-4',
            columnUnfreeze: 'i-[mdi/table-star] !h-4',
            columnFreezeUpTo: 'i-[mdi/table-star] !h-4',
            columnUnfreezeUpTo: 'i-[mdi/table-star] !h-4',
            columnHide: 'i-[mdi/eye-off-outline] !h-4',
          },
          finder: {
            container: 'max-w-full',
            title: {
              container: 'border-b-secondary group flex h-7 cursor-pointer select-none items-center border-b-2',
            },
          },
        },
      },
      dropdown: {
        grid: {
          basic: {
            theme: 'dropdown',
            placement: 'bottom-start',
            triggers: ['click'],
            delay: 0,
            handleResize: true,
            autoHide: true,
            contentClass: 'max-h-80 overflow-y-auto',
            popperClass:
              'h-fit divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black/5 overflow-hidden',
          },
          actions: {
            basic: {
              $extends: '../basic',
              distance: 3,
              skidding: -6,
              popperClass: extend('shrink-0 w-56'),
              mainContentClass: 'shrink-1 flex grow-0 basis-auto flex-col overflow-auto py-1',
              subHeadingClass: 'text-xs text-gray-700',
            },
            density: {
              mainContentClass: extend('gap-2'),
              placement: 'bottom-end',
              skidding: 6,
            },
          },
          header: {
            $extends: './basic',
            distance: 0,
            skidding: 0,
            popperClass: extend('w-[264px]'),
            popperHideTriggers: triggers => [...triggers, 'click'],
            instantMove: true,
          },
        },
      },
      button: {
        grid: {
          actions: {
            basic: {
              size: 'md',
              idle: '!py-1 shrink-0 font-medium text-gray-700 border-2 border-solid border-white hover:bg-primary-100 hover:border-primary-100 truncate',
              active: 'bg-primary-100 !border-primary-100 hover:bg-primary-100  hover:!border-primary-200',
              selected: 'bg-primary-200 hover:bg-primary-200 !border-primary-200 hover:!border-primary-200',
            },
            hide: {
              $extends: './basic',
              icon: 'i-[ic/round-visibility-off] !h-4 !transform-none',
            },
            group: {
              $extends: './basic',
              icon: 'i-[mdi/list-box-outline] !transform-none',
            },
            sort: {
              $extends: './basic',
              icon: 'i-[mdi/sort] !h-4 !transform-none',
            },
            filter: {
              icon: 'i-[ic/outline-filter-list] !h-[18px] !-scale-x-100',
            },
            pivot: {
              icon: 'i-[ic/outline-pivot-table-chart] !h-4 !-scale-x-100',
            },
            transpose: {
              icon: 'i-[mdi/table-pivot] !h-4 !-scale-x-100',
            },
            plot: {
              icon: 'i-[ic/outline-bar-chart] !h-[18px] !-scale-x-100',
            },
            density: {
              icon: 'i-[ic/baseline-format-line-spacing] !h-4 !-scale-x-100',
            },
            rows: {
              $extends: './basic',
              icon: 'i-[mdi/table-row] !h-4',
            },
            columns: {
              $extends: './basic',
              icon: 'i-[mdi/table-column] !h-4',
            },
            aggregates: {
              $extends: './basic',
              icon: 'i-[mdi/function-variant] !h-4',
            },
            swap: {
              $extends: './basic',
              icon: 'i-[ic/baseline-swap-horiz] !h-4',
            },
            hideDetails: {
              $extends: './basic',
              icon: 'i-lucide/square !h-4',
            },
            showDetails: {
              $extends: './basic',
              icon: 'i-lucide/columns !h-4',
            },
            exportCSV: {
              $extends: './basic',
              icon: 'i-[la/file-csv] !h-5',
            },
            exportXLS: {
              $extends: './basic',
              icon: 'i-[mdi/microsoft-excel] !h-5',
            },
            addRow: {
              $extends: './basic',
              icon: 'i-[ic/baseline-add] !h-5',
            },
            delete: {
              $extends: './basic',
              icon: 'i-[carbon/trash-can] !h-4',
              height: 'h-2',
              width: 'w-2',
            },
            duplicate: {
              $extends: './basic',
              icon: 'i-[mdi/content-duplicate] !h-4',
              height: 'h-2',
              width: 'w-2',
            },
            finder: {
              $extends: './basic',
              icon: 'i-[ic/outline-account-tree] !h-4',
            },
          },
          subactions: {
            basic: {
              size: 'sm',
              idle: 'grow !py-1 font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 active:bg-gray-300',
              disabled: 'cursor-default !bg-gray-50 !text-gray-400 hover:!bg-gray-50',
            },
            expandToLevel: {
              size: 'xs',
              idle: extend('!grow-0'),
            },
            emptyShowAll: {
              idle: extend('!grow-0'),
            },
            emptyClearSearch: {
              idle: extend('!grow-0'),
            },
          },
          header: {
            action: {
              size: 'xs',
              idle: 'ml-auto !py-1 z-10 transition-colors hidden font-medium text-gray-700 bg-primary-50 group-hover:bg-gray-100 group-hover:!flex group-hover:hover:bg-gray-200 group-hover:active:bg-gray-300 ring-white',
              selected: '!bg-gray-200 !flex group-hover:hover:ring-2',
              iconClass: '!h-4',
            },
            toggle: {
              size: 'xs',
              idle: '-ml-1 mr-1 !py-1 transition-colors font-medium text-gray-700 bg-primary-50 group-hover:bg-gray-100 group-hover:hover:bg-gray-200 group-hover:active:bg-gray-300',
              iconClass: '!h-4',
            },
          },
        },
      },
      listItem: {
        basic: {
          idle: 'hover:bg-gray-50 active:bg-gray-100',
        },
        grid: {
          idle: 'h-8 text-gray-800 flex gap-2 items-center px-4 hover:bg-gray-50',
        },
      },
    },
  },
}
