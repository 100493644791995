<template>
  <div id="spreadsheet-simple" class="spr spreadsheet-wrapper">
    <div class="spreadsheet" ref="spreadsheetRef">
      <div class="spreadsheet-inner">
        <div class="line header">
          <div
            v-for="(column, _) in columns"
            :key="column.name"
            :class="[
              'cell',
              'cell-' + dash(column.name),
              sort.find(v => v === column.name) && 'sort asc',
              sort.find(v => v === '-' + column.name) && 'sort desc',
            ]"
            @click="column.name !== 'check' && handleSort(column.name)"
          >
            <slot :name="'header-' + dash(column.name)" :column="column.name">
              <div v-text="titleize($root.t[column.name] || column.name)"></div>
            </slot>
            <button
              v-if="column.name !== 'check'"
              class="resizer"
              @mousedown.stop="startResize($event, column)"
            ></button>
          </div>
        </div>
        <div v-for="(row, rowIndex) in sortedData" :key="rowIndex" class="line">
          <div
            v-for="(column, _) in columns"
            :key="column.name"
            :class="['cell', 'cell-' + dash(column.name), format_class(row, column)]"
          >
            <slot :name="'cell-' + dash(column.name)" :line="row" :column="column.name">
              <div v-text="format(row, column)"></div>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div v-html="dynamicStyles"></div>
  </div>
</template>

<script setup>
import { computed, nextTick, ref, watch, onMounted } from 'vue'
import { titleize } from '../../utils/string'

/**
 * This component is created to simplify the existing spreadsheet.vue with just enough features as below:
  - stylesheets override compatibility from consumer components
  - column width auto-resize and resizable
  - default sorting and sortable
 */
const props = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => [],
  },
  options: {
    type: Object,
    required: true,
    default: () => ({
      columns: [],
      sort: [],
    }),
  },
})

const columns = ref([])
const sort = ref([])

const spreadsheetRef = ref(null)
const columnWidths = ref({})
const isResizing = ref(false)

const checkerColumnWidth = 30
const columnMinWidth = 100
const columnMaxWidth = 250
const autoShrinkRatio = 0.8

// dynamic column width base on max cell width
const updateColumnWidths = () => {
  const spreadsheet = spreadsheetRef.value
  if (!spreadsheet) return

  columns.value.forEach(col => {
    if (col.name === 'check') {
      columnWidths.value[col.name] = checkerColumnWidth
      return
    }

    const colCells = spreadsheet.querySelectorAll(`.cell-${dash(col.name)}`)
    let maxWidth = 0

    colCells.forEach(cell => {
      const width = cell.children[0]?.scrollWidth || 0
      maxWidth = Math.max(maxWidth, width)
    })

    columnWidths.value[col.name] = Math.min(maxWidth, columnMaxWidth)
  })
}

// use flex layout to control column widths when resizing
const columnStyles = computed(() => {
  const spreadsheet = spreadsheetRef.value
  if (!columns.value || !spreadsheet) return ''

  // we can remove this inline css when the old spreadsheet style is removed
  let style = `#spreadsheet-simple .spreadsheet-inner { flex: 1; overflow: auto;}
   #spreadsheet-simple .line.header { position: sticky; left: unset; }`

  style += columns.value
    .map(col => {
      const width = columnWidths.value[col.name]
      const isShirnkable = !['check', 'actions'].includes(col.name) && width > columnMinWidth
      const shrinkRatio = isShirnkable ? autoShrinkRatio : 1

      return `
      #spreadsheet-simple .cell-${dash(col.name)} { 
        flex: 1 1 ${width}px;
        width: ${width * shrinkRatio}px;
        min-width: ${width * shrinkRatio}px;
      }
    `
    })
    .join('\n')

  return style
})

const dynamicStyles = computed(() => `<style>${columnStyles.value}</style>`)

const _refresh = () => {
  nextTick(() => {
    updateColumnWidths()
  })
}

const refresh = _refresh.debounce(100)

watch(
  () => props.options.columns,
  newColumns => {
    columns.value = (newColumns || []).map(c => ({ name: c.name || c }))
    refresh()
  },
  { immediate: true },
)

watch(
  () => props.options.sort,
  newSort => {
    sort.value = newSort || []
    refresh()
  },
  { immediate: true },
)

watch(
  () => props.data,
  () => {
    refresh()
  },
  { immediate: true },
)

onMounted(() => {
  // to avoid column width flash during the first rendering
  updateColumnWidths()
})

const sortedData = computed(() => {
  if (!sort.value.length || !Array.isArray(props.data)) return props.data

  return [...props.data].sort((a, b) => {
    const sortKey = sort.value[0]
    const isDesc = sortKey.startsWith('-')
    const key = isDesc ? sortKey.slice(1) : sortKey
    const aVal = a[key]
    const bVal = b[key]

    if (typeof aVal === 'number' && typeof bVal === 'number') {
      return isDesc ? bVal - aVal : aVal - bVal
    }

    const compareResult = String(aVal).localeCompare(String(bVal))

    return isDesc ? -compareResult : compareResult
  })
})

const handleSort = column => {
  if (isResizing.value) return
  const currentSort = sort.value[0]
  if (currentSort === column) {
    sort.value = [`-${column}`]
  } else if (currentSort === `-${column}`) {
    sort.value = []
  } else {
    sort.value = [column]
  }
}

const startResize = (event, column) => {
  isResizing.value = true
  const startX = event.pageX
  const startWidth = columnWidths.value[column.name]

  const handleMouseMove = e => {
    e.stopPropagation()
    columnWidths.value[column.name] = Math.max(30, startWidth + e.pageX - startX)
  }

  const handleMouseUp = e => {
    e.stopPropagation()
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)

    // to prevent click event been triggered
    const timer = setTimeout(() => {
      isResizing.value = false
      clearTimeout(timer)
    }, 100)
  }

  document.addEventListener('mousemove', handleMouseMove)
  document.addEventListener('mouseup', handleMouseUp)
}

// Utility functions
const dash = str => {
  return String(str)
    .split(' ')
    .map(s => s.toLowerCase())
    .join('-')
}

const format = (line, column) => {
  if (props.options.format) {
    return props.options.format(line, column)
  }
  return line[column.name]
}

const format_class = (line, column) => {
  if (props.options.format_class) {
    return props.options.format_class(line, column)
  }
  return column.type || ''
}
</script>

<style>
.spr.spreadsheet-wrapper {
  --colors-selection: #0288d1;
  --colors-selection-light: #dff4ff;
  --colors-background: #eee;

  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 80vh;
  width: 100%;
  overflow: hidden;
}

.spr .spreadsheet-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 8px;
  overflow: auto;
}

.spr .spreadsheet .line.header {
  z-index: 2;
  position: sticky;
  top: 0;
  left: unset;
  background: white;
  font: var(--p2);
  font-weight: 500;
}

.spr .spreadsheet .line {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 30px;
  background: white;
  width: 100%;
}

.spr .spreadsheet .line:hover {
  background: var(--colors-primary-light);
}

.spr .spreadsheet .cell {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  background: inherit;
  box-shadow: inset 0 0 0 0.5px var(--colors-background);
}

.spr .spreadsheet .cell > * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 30px;
  padding: 0 8px;
}

.spr .spreadsheet .cell > *::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.spr .spreadsheet .line.header .cell {
  cursor: pointer;
  box-shadow: none;
}

.spr .spreadsheet .line.header .cell.sort.asc {
  text-decoration: underline;
}

.spr .spreadsheet .line.header .cell.sort.desc {
  text-decoration: underline double;
}

.spr .spreadsheet .line.header .cell .resizer {
  position: absolute;
  right: -1px;
  cursor: col-resize;
  width: 6px;
  height: 100%;
  padding: 0;
  border: 0 solid white;
  outline: 0;
  transition: none;
  background: none;
  box-shadow: none;
  margin: 0;
}

.spr .spreadsheet .line.header .cell:hover .resizer {
  background: var(--colors-selection);
  opacity: 0.6;
  border-width: 0 1px 0 1px;
  min-width: 2px;
}

.spr .spreadsheet .line.header .cell:hover .resizer:active {
  opacity: 1;
}

.spr .spreadsheet .line.header .cell:hover .resizer:active::before {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

.spr .spreadsheet.stripped .line:nth-child(even) {
  background: var(--colors-primary-light, #f4f5f6);
}

.spr .cell.number {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.spr .cell-check {
  max-width: 30px;
}

.spr .cell-check input {
  width: 30px;
  height: 30px;
}

.spr .cell-check .resizer {
  display: none;
}
</style>
