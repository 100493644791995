<template>
  <transition @leave="leave">
    <loader v-if="loading" />
  </transition>

  <div class="header">
    <div class="column" style="gap: 8px">
      <div class="row">
        <h1 style="margin-right: 8px">{{ $root.t[`${props.folderType}_folders_page`].title }}</h1>
        <badge v-if="!!contentFolders" :count="contentFolders.length" />
      </div>
      <p class="subtitle">
        {{ $root.t[`${props.folderType}_folders_page`].subtitle }}
      </p>
    </div>
    <div class="search-filters">
      <autocomplete
        v-model="search"
        class="right"
        :data="autocompleteData"
        :options="{ placeholder: $root.t.search }"
      />
    </div>
  </div>

  <tabs
    v-if="specialtyTabs?.length"
    :tabs="specialtyTabs"
    :activeTab="activeTab"
    @send-active-tab="handleChangeActiveTab"
  >
    <template v-for="tab in specialtyTabs" :key="tab.name" #[tab.name]>
      <div class="expand template-container block" v-if="loading === false">
        <div class="container">
          <span v-if="!contentFolders?.length" style="margin: 16px">
            {{ $root.t[`${props.folderType}_folders_page`].no_folders }}
          </span>

          <div v-else class="content">
            <spreadsheet-simple
              class="stripped expand spreadsheet"
              :data="filteredContentFolders"
              :options="{
                columns: [
                  { name: 'name', size: 1 },
                  { name: 'variables', size: 1 },
                  { name: 'itemsCount', size: 0.1 },
                  { name: 'actions', size: 1 },
                ],
                editable: false,
              }"
            >
              <template v-slot:name="{ line }">
                {{ line.name || '-' }}
              </template>
              <template v-slot:variables="{ line }">
                {{ line.variables || '-' }}
              </template>
              <template v-slot:cell-items-count="{ line }">
                {{ line.itemsCount ?? 0 }}
              </template>
              <template v-slot:cell-actions="{ line }">
                <div class="row" style="align-items: end">
                  <button class="ghost" :tt="$root.t.edit" @click="viewContentFolder(line.id)">
                    <ui-asset name="icon_edit" color="var(--colors-text)" />
                  </button>
                  <button
                    v-if="!line.readOnly"
                    class="ghost"
                    :tt="$root.t.delete"
                    @click="setConfirmDeleteContentFolderId(line.id)"
                  >
                    <ui-asset name="icon_trash" color="var(--colors-text)" />
                  </button>
                </div>
              </template>
            </spreadsheet-simple>
          </div>
        </div>

        <div class="row">
          <button class="primary" @click="showCreateContentFolderPopup = true">
            {{ $root.t[`${props.folderType}_folders_page`].create }}
          </button>
        </div>
      </div>
    </template>
  </tabs>

  <popup :show="confirmDeleteContentFolderId">
    <template v-slot:header>{{ $root.t.confirmation_remove }}</template>
    <template v-slot:content>{{ $root.t[`${props.folderType}_folders_page`].confirmation_remove_folder }}</template>
    <template v-slot:action>
      <button class="secondary-action" @click="setConfirmDeleteContentFolderId(null)">
        {{ $root.t.confirmation_btn_close }}
      </button>
      <button
        class="main-action"
        @click="confirmDeleteContentFolderId && deleteContentFolder(confirmDeleteContentFolderId)"
      >
        {{ $root.t.confirmation_btn_delete }}
      </button>
    </template>
  </popup>

  <create-content-folder-popup
    :folder-type="props.folderType"
    :show="showCreateContentFolderPopup"
    :specialties="specialties"
    @close="showCreateContentFolderPopup = false"
    @created="handleContentFolderCreated"
  />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'

import specialtyService, { Specialty } from '@100-m/hauru/src/services/SpecialtyService'
import contentFolderService, { ContentFolder } from '@100-m/hauru/src/services/ContentFolderService'
import { deleteFolder } from '../../../builder/lib/assetManagement'

const props = defineProps<{
  folderType: 'image' | 'narrative'
}>()

const contentFolders = ref<ContentFolder[] | null>(null)
const specialties = ref<Specialty[] | null>(null)

const loading = ref<boolean>(false)
const search = ref('')

const specialtyTabs = ref([])
const activeTab = ref('')

const confirmDeleteContentFolderId = ref<number | null>(null)

const showCreateContentFolderPopup = ref<boolean>(false)

onMounted(async () => {
  loading.value = true

  const _specialties = await specialtyService.list()
  specialties.value = _specialties

  contentFolders.value = await contentFolderService.findManyBySpecialtyName({
    specialtyName: _specialties?.[0].name,
  })

  specialtyTabs.value = _specialties.map((specialty: Specialty) => ({
    name: specialty.name,
    title: specialty.name,
  }))

  loading.value = false
})

const handleChangeActiveTab = async (tabName: string) => {
  activeTab.value = tabName

  contentFolders.value = await contentFolderService.findManyBySpecialtyName({
    specialtyName: tabName,
  })
}

const formattedContentFolders = computed(() => {
  return contentFolders.value?.map(contentFolder => {
    const variables = contentFolder.variables ? contentFolder?.variables.toSorted().join(', ') : '-'

    return {
      id: contentFolder.id,
      name: contentFolder.name,
      specialtyName: contentFolder.specialtyName,
      variables,
      itemsCount: contentFolder.contentItems?.length || 0,
      readOnly: contentFolder.readOnly,
    }
  })
})

const filteredContentFolders = computed(() => {
  if (!search.value) {
    return formattedContentFolders.value
  }

  const [key, value] = search.value.split('.')

  return formattedContentFolders.value?.filter(contentFolder => contentFolder[key as 'name'] === value)
})

const autocompleteData = computed(() => {
  return formattedContentFolders.value?.reduce(
    (acc, contentFolder) => {
      acc.name[contentFolder.name] = contentFolder.name
      return acc
    },
    { name: {} as Record<string, string> },
  )
})

const handleContentFolderCreated = (newContentFolder: ContentFolder) => {
  contentFolders.value = [...(contentFolders.value || []), newContentFolder]
  showCreateContentFolderPopup.value = false

  $root.$router.push({
    path: $root.appath + `${props.folderType}-folder`,
    query: { id: newContentFolder.id.toString() },
  })
}

const viewContentFolder = (id: number) => {
  $root.$router.push({
    path: $root.appath + `${props.folderType}-folder`,
    query: { id: id.toString() },
  })
}

const setConfirmDeleteContentFolderId = async (id: number | null) => {
  confirmDeleteContentFolderId.value = id
}

const deleteContentFolder = async (id: number) => {
  const deletedContentFolder = await contentFolderService.deleteOne({ id })

  if (deletedContentFolder.id) {
    // Todo: Submit event on delete folder
    if (props.folderType === 'image') {
      await deleteFolder({
        filenameWithPath: `assets/images/${deletedContentFolder.name}`,
        isPrivateFolder: false,
      })
    }

    contentFolders.value = contentFolders.value?.filter(contentFolder => contentFolder.id !== id) || []

    $root.toast({
      description: $root.t[`${props.folderType}_folders_page`].delete_folder_success,
      type: 'success',
      timeout: 5000,
    })
  }

  setConfirmDeleteContentFolderId(null)
}
</script>

<style scoped>
.content-folders .cell-format {
  flex: unset !important;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subtitle {
  font-style: italic;
  margin-top: -5px;
}
.search-filters {
  padding-top: 8px;
  min-width: 40% !important;
  display: flex;
  justify-content: flex-end;
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: row;
}
:deep(.cell-items-count) {
  flex: 0.4 !important;
  text-align: start;
}
:deep(.cell-items-count:not(:has(*))) {
  padding-left: 8px;
}
:deep(.cell-actions) {
  flex: 0.4 !important;
}
</style>
