<style scoped>
.image-folders .cell-format {
  flex: unset !important;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.subtitle {
  font-style: italic;
  margin-top: -5px;
}
.search-filters {
  padding-top: 8px;
  min-width: 40% !important;
  display: flex;
  justify-content: flex-end;
}
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: row;
}
:deep(.cell-images-count) {
  flex: 0.4 !important;
  text-align: start;
}
:deep(.cell-images-count:not(:has(*))) {
  padding-left: 8px;
}
:deep(.cell-actions) {
  flex: 0.4 !important;
}
</style>

<template>
  <transition @leave="leave">
    <loader v-if="loading" />
  </transition>

  <div class="header">
    <div class="column" style="gap: 8px">
      <div class="row">
        <h1 style="margin-right: 8px">{{ $root.t.image_folders_page.title }}</h1>
        <badge v-if="!!imageFolders" :count="imageFolders.length" />
      </div>
      <p class="subtitle">
        {{ $root.t.image_folders_page.subtitle }}
      </p>
    </div>
    <div class="search-filters">
      <autocomplete
        v-model="search"
        class="right"
        :data="autocompleteData"
        :options="{ placeholder: $root.t.search }"
      />
    </div>
  </div>

  <tabs
    v-if="specialtyTabs?.length"
    :tabs="specialtyTabs"
    :activeTab="activeTab"
    @send-active-tab="handleChangeActiveTab"
  >
    <template v-for="tab in specialtyTabs" :key="tab.name" #[tab.name]>
      <div class="expand template-container block" v-if="loading === false">
        <div class="container">
          <span v-if="!imageFolders?.length" style="margin: 16px">
            {{ $root.t.image_folders_page.no_image_folders }}
          </span>

          <div v-else class="content">
            <spreadsheet-simple
              class="stripped expand spreadsheet"
              :data="filteredImageFolders"
              :options="{
                columns: [
                  { name: 'name', size: 1 },
                  { name: 'variables', size: 1 },
                  { name: 'imagesCount', size: 0.1 },
                  { name: 'actions', size: 1 },
                ],
                editable: false,
              }"
            >
              <template v-slot:name="{ line }">
                {{ line.name || '-' }}
              </template>
              <template v-slot:variables="{ line }">
                {{ line.variables || '-' }}
              </template>
              <template v-slot:cell-images-count="{ line }" style="width: 100px">
                {{ line.imagesCount ?? 0 }}
              </template>
              <template v-slot:cell-actions="{ line }">
                <div class="row" style="align-items: end">
                  <button class="ghost" :tt="$root.t.edit" @click="viewImageFolder(line.id)">
                    <ui-asset name="icon_edit" color="var(--colors-text)" />
                  </button>
                  <button class="ghost" :tt="$root.t.delete" @click="setConfirmDeleteImageFolderId(line.id)">
                    <ui-asset name="icon_trash" color="var(--colors-text)" />
                  </button>
                </div>
              </template>
            </spreadsheet-simple>
          </div>
        </div>

        <div class="row">
          <button class="primary" @click="showCreateImageFolderPopup = true">
            {{ $root.t.image_folders_page.create }}
          </button>
        </div>
      </div>
    </template>
  </tabs>

  <create-image-folder-popup
    :show="showCreateImageFolderPopup"
    :specialties="specialties"
    @close="showCreateImageFolderPopup = false"
    @created="handleImageFolderCreated"
  />

  <popup :show="confirmDeleteImageFolderId">
    <template v-slot:header>{{ $root.t.confirmation_remove }}</template>
    <template v-slot:content>{{ $root.t.image_folders_page.confirmation_remove_image_folder }}</template>
    <template v-slot:action>
      <button class="secondary-action" @click="setConfirmDeleteImageFolderId(null)">
        {{ $root.t.confirmation_btn_close }}
      </button>
      <button class="main-action" @click="confirmDeleteImageFolderId && deleteImageFolder(confirmDeleteImageFolderId)">
        {{ $root.t.confirmation_btn_delete }}
      </button>
    </template>
  </popup>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import imageFolderService, { ImageFolder } from '@100-m/hauru/src/services/ImageFolderService'
import specialtyService, { Specialty } from '@100-m/hauru/src/services/SpecialtyService'
import { deleteFolder } from '../../builder/lib/assetManagement'

const imageFolders = ref<ImageFolder[] | null>(null)
const specialties = ref<Specialty[] | null>(null)

const loading = ref<boolean>(false)
const search = ref<string | null>(null)

const specialtyTabs = ref<{ name: string; title: string }[] | null>(null)
const activeTab = ref<string | null>(null)

const confirmDeleteImageFolderId = ref<number | null>(null)

const showCreateImageFolderPopup = ref<boolean>(false)

onMounted(async () => {
  loading.value = true

  const _specialties = await specialtyService.list()
  specialties.value = _specialties

  imageFolders.value = await imageFolderService.findManyBySpecialtyName({
    specialtyName: _specialties?.[0].name,
  })

  specialtyTabs.value = _specialties.map((specialty: Specialty) => ({
    name: specialty.name,
    title: specialty.name,
  }))

  loading.value = false
})

const handleChangeActiveTab = async (tabName: string) => {
  activeTab.value = tabName

  imageFolders.value = await imageFolderService.findManyBySpecialtyName({
    specialtyName: tabName,
  })
}

const formattedImageFolders = computed(() => {
  return imageFolders.value?.map(imageFolder => {
    const variables = imageFolder.variables ? imageFolder?.variables.toSorted().join(', ') : '-'

    return {
      id: imageFolder.id,
      name: imageFolder.name,
      specialtyName: imageFolder.specialtyName,
      variables,
      imagesCount: imageFolder.images?.length || 0,
    }
  })
})

const autocompleteData = computed(() => {
  return formattedImageFolders.value?.reduce(
    (acc, imageFolder) => {
      acc.name[imageFolder.name] = imageFolder.name
      return acc
    },
    { name: {} as Record<string, string> },
  )
})

const filteredImageFolders = computed(() => {
  if (!search.value) {
    return formattedImageFolders.value
  }

  const [key, value] = search.value.split('.')

  return formattedImageFolders.value?.filter(imageFolder => imageFolder[key as 'name'] === value)
})

const handleImageFolderCreated = (newImageFolder: ImageFolder) => {
  imageFolders.value = [...(imageFolders.value || []), newImageFolder]
  showCreateImageFolderPopup.value = false
  $root.$router.push({
    path: $root.appath + 'image-folder',
    query: { id: newImageFolder.id.toString() },
  })
}

function viewImageFolder(id: number) {
  $root.$router.push({
    path: $root.appath + 'image-folder',
    query: { id: id.toString() },
  })
}

function setConfirmDeleteImageFolderId(id: number | null) {
  confirmDeleteImageFolderId.value = id
}

async function deleteImageFolder(id: number) {
  const deletedImageFolder = await imageFolderService.deleteOne({ id })

  if (deletedImageFolder.id) {
    await deleteFolder({
      filenameWithPath: `assets/images/${deletedImageFolder.name}`,
      isPrivateFolder: false,
    })

    imageFolders.value = imageFolders.value?.filter(imageFolder => imageFolder.id !== id) || []

    $root.toast({
      description: $root.t.image_folders_page.delete_image_folder_success,
      type: 'success',
      timeout: 5000,
    })
  }

  setConfirmDeleteImageFolderId(null)
}
</script>

<script lang="ts">
export const additions = { icon: 'icon-image-multiple' }
</script>
