<template>
  <loader v-if="loading" />

  <div v-else class="container">
    <span v-if="!dataReportResults?.length">{{ $root.t.data_report_results.no_data_report_results }}</span>
    <spreadsheet-simple
      v-else
      class="stripped expand spreadsheet"
      :data="formattedDataReportResults"
      :options="{
        columns: dynamicColumns,
        editable: false,
      }"
    >
      <template v-slot:header-domain>
        <div>{{ t.period }}</div>
      </template>
      <template v-slot:cell-id="{ line }">
        {{ line.id }}
      </template>
      <template v-slot:cell-filename="{ line }">
        {{ line.filename || '-' }}
      </template>
      <template v-slot:cell-updated-at="{ line }">
        {{ line.updatedAt || $root.t.data_report_results.no_execution }}
      </template>
      <template v-slot:cell-language="{ line }">
        <svg-icon
          class="icon"
          :tt="line.language.upper().slice(0, 2)"
          :name="'flag-' + line.language.lower().slice(0, 2)"
        />
      </template>
      <template v-slot:cell-jurisdiction="{ line }">
        <svg-icon class="icon" :tt="line.jurisdiction.upper()" :name="'flag-' + line.jurisdiction.lower()" />
      </template>
      <template v-slot:cell-actions="{ line }">
        <div class="row">
          <button class="ghost" :tt="$root.t.edit" @click="edit(line.id)">
            <ui-asset name="icon_edit" color="var(--colors-text)" />
          </button>
          <button v-if="isAdmin" class="ghost" :tt="$root.t.delete" @click="confirmDeleteItemId = line.id">
            <ui-asset name="icon_trash" color="var(--colors-text)" />
          </button>
        </div>
      </template>
    </spreadsheet-simple>

    <div class="row">
      <button class="primary" style="margin-right: 8px" @click="createDataReportResult">
        {{ $root.t.data_report_results.create_data_report_result }}
      </button>
      <button class="secondary" @click="cancel">{{ $root.t.cancel }}</button>
    </div>
  </div>

  <popup :show="confirmDeleteItemId">
    <template v-slot:header>
      {{ $root.t.confirmation_remove }}
    </template>
    <template v-slot:content>
      {{ $root.t.data_report_results.delete_confirmation }}
    </template>
    <template v-slot:action>
      <div class="row" style="margin-top: 16px">
        <button class="secondary-action" @click="confirmDeleteItemId = null">
          {{ $root.t.cancel }}
        </button>
        <button class="main-action" @click="erase(confirmDeleteItemId)">
          {{ $root.t.delete }}
        </button>
      </div>
    </template>
  </popup>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.spreadsheet {
  max-height: calc(100vh - 260px);
}
</style>

<script setup lang="ts">
import dataReportResultService, { DataReportResult } from '@100-m/hauru/src/services/DataReportResultService'
import { computed, onMounted, ref, defineEmits } from 'vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'

const dataReportResults = ref<DataReportResult[] | null>(null)
const dataReport = ref(null)
const loading = ref<boolean>(false)
const confirmDeleteItemId = ref<number | null>(null)
const isAdmin = ref<boolean>(['admin', 'admin-reporting'].includes($root.profile?.role))

const props = defineProps({
  dataReportDefinitionId: {
    type: Number,
    required: true,
  },
  formattedDataReportResults: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['send-data-results-informations'])

const emitDataResults = (drResultsInformations: any) => {
  emit('send-data-results-informations', drResultsInformations)
}

// Determine columns to display from first data report result's runVariables keys
const dynamicColumns = computed(() => {
  const specialtyColumn = dataReport.value?.settingVariableParameters
    .filter(param => !param.argument && param.name !== 'language')
    .map(param => param.name)

  return ['id', ...specialtyColumn, 'language', 'jurisdiction', 'filename', 'updatedAt', 'actions']
})

const transformDataReportResults = () => {
  if (!dataReportResults.value) {
    return []
  }

  const drResultsFormatted = dataReportResults.value.map(result => {
    const jurisdiction =
      typeof result.runVariables.language === 'string' ? result.runVariables.language.slice(-2) : null

    return {
      ...result.runVariables,
      id: result.id,
      filename: result.metaData?.filename,
      jurisdiction,
      updatedAt: new Date(result.updatedAt).format('YYYY-MM-DD hh:mm'),
    }
  })

  emitDataResults({
    dataReportResults: drResultsFormatted,
    drResultsColumns: dynamicColumns.value,
  })
}

onMounted(async () => {
  loading.value = true

  dataReportResults.value = await dataReportResultService.findMany({
    dataReportDefinitionId: props.dataReportDefinitionId,
  })

  dataReport.value = await dataReportService.getById(props.dataReportDefinitionId)

  transformDataReportResults()

  loading.value = false
})

function edit(id: number) {
  $root.$router.push({
    path: $root.appath + 'data-report-result',
    query: { id, dataReportDefinitionId: props.dataReportDefinitionId },
  })
}

async function erase(id: number) {
  // because "delete" is already a reserved JS keyword
  if (isAdmin.value) {
    await dataReportResultService.erase(id)
    confirmDeleteItemId.value = null
    dataReportResults.value = await dataReportResultService.findMany({
      dataReportDefinitionId: props.dataReportDefinitionId,
    })
  }
}

function createDataReportResult() {
  $root.$router.push({
    path: $root.appath + 'data-report-result',
    query: { dataReportDefinitionId: props.dataReportDefinitionId },
  })
}

function cancel(): void {
  $root.$router.push({
    path: $root.appath + 'data-reports',
  })
}
</script>
