<template>
  <div
    ref="chart"
    class="nx-pie-label"
    :class="props?.options?.variant === 'pie' ? 'variant-pie' : 'variant-default'"
  ></div>
</template>

<script setup>
import * as d3 from 'd3'
import { computed, ref, onMounted, watch } from 'vue'
import { balanceDataArrayForPieChart, getIntersectionPoint } from '../../utils/pie-label.util'
const props = defineProps(['data', 'options'])
const chart = ref(null)
/**
- Before draw the chart with its labels, a core function `balanceDataArrayForPieChart` is invoked to check if they are narrow areas which will cause an overlap between labels
  - If yes, the position of those narrow segments will be re-arranged, like a circular array been balanced, which will make those segments can be almost evenly distributed between the left side and the right side of a pie chart
  - If no, the original input data will be used for rendering
- The rendering process is to position label content and draw polyline between pie body and the labels
  - To address label content is overflow issue
    - a dynamic `labelWidth` is been calculated base on the available space 
  - To address label may still overlap with each other
    - An increasing gap between consecutive narrow segments will be added or reduced
        - An offset will be applied to the chart if a label is exceed the boundary
    - If they are too many narrow segments, instead of just around its segment, all the labels will be aligned vertically
 */
function renderChart() {
  if (!chart.value || !props.data) return

  const data = props.data
    .filter(v => v[props.options.y] > 0)
    .map(x => ({ key: x[props.options.x], value: x[props.options.y] }))
  const total = computed(() => data.map(v => v.value).sum())
  const n = data.length
  const { hasNarrowArea, balancedData, startIndex, endIndex, sliceAtIndex } = balanceDataArrayForPieChart(data)

  // pie chart controls
  const baseMargin = 20
  const width = chart.value.clientWidth
  const height = chart.value.clientHeight
  const minWidth = Math.min(width, height) - baseMargin
  const chartRadius = n < 4 ? minWidth / 3 : minWidth / 3 - baseMargin
  const innerRadius = props.options.variant === 'pie' ? 0 : 0.5 * chartRadius
  const originOuterRadius = chartRadius + baseMargin / 2

  // label controls
  const labelContentMaxHeight = 40
  const labelGapHeight = 24
  const overlapBetweenPolylineAndLabel = 2
  const barLabelOffsetBetweenBreakpoint = 4
  const chartBottomNarrowArea = [0.8 * Math.PI, 1.2 * Math.PI]
  const chartTopNarrowArea = [0.2 * Math.PI, 1.8 * Math.PI]
  const labelMaxHeight = height / 2 - baseMargin
  const rightInitialLableHeight = (endIndex - sliceAtIndex) * labelGapHeight + originOuterRadius

  d3.select(chart.value).selectAll('svg').remove()

  const svg = d3.select(chart.value).append('svg').attr('viewBox', `0 0 ${width} ${height}`)

  const g = svg.append('g').attr('transform', `translate(${width / 2},${height / 2})`)

  const pie = d3
    .pie()
    .value(d => d[props.options.y])
    .sort(null)
    .startAngle(0)
    .endAngle(2 * Math.PI)

  const pieData = pie(balancedData || data)

  g.selectAll('path')
    .data(pieData)
    .enter()
    .append('path')
    .attr('d', d3.arc().innerRadius(innerRadius).outerRadius(chartRadius))
    .style('fill', (d, i) => props.options.palette[i])

  if (props.options.variant !== 'pie') {
    g.append('circle').attr('r', innerRadius).attr('class', 'center-circle').style('fill', 'white')
  }

  let adjustedOuterRadius = originOuterRadius
  let rightLabelHeight = rightInitialLableHeight
  let leftLabelHeight = originOuterRadius
  let isExceedBoundary = false

  pieData.forEach((v, i, ds) => {
    const prev = ds.slice(0, i).map(props.options.y).sum()
    const next = prev + v[props.options.y]
    const startAngle = 2 * Math.PI * (prev / total.value)
    const endAngle = 2 * Math.PI * (next / total.value)
    let midAngle = startAngle + (endAngle - startAngle) / 2

    if (startAngle > endAngle) {
      const absoluteRadians = startAngle + (2 * Math.PI - startAngle + endAngle) / 2
      midAngle = absoluteRadians > 2 * Math.PI ? absoluteRadians - 2 * Math.PI : absoluteRadians
    }

    const isRightSide = midAngle < Math.PI

    const arc = d3.arc().innerRadius(innerRadius).outerRadius(chartRadius).startAngle(startAngle).endAngle(endAngle)

    // origin index before array been balanced
    const originIndex = (i + sliceAtIndex) % n

    // if (v[props.options.y] / total.value < lowValueThreshold) {
    if (hasNarrowArea && originIndex >= startIndex && originIndex <= endIndex) {
      if (isRightSide) {
        adjustedOuterRadius = rightLabelHeight
        rightLabelHeight -= labelGapHeight
      } else {
        adjustedOuterRadius = leftLabelHeight
        leftLabelHeight += labelGapHeight
      }

      if (isExceedBoundary === false && adjustedOuterRadius > labelMaxHeight) {
        isExceedBoundary = true
      }
    } else {
      adjustedOuterRadius = originOuterRadius
    }

    const outerArc = d3
      .arc()
      .innerRadius(adjustedOuterRadius)
      .outerRadius(adjustedOuterRadius)
      .startAngle(startAngle > endAngle ? startAngle - 2 * Math.PI : startAngle)
      .endAngle(endAngle)

    // line insertion in the slice
    const posA = arc.centroid()
    // line break position
    const posB = outerArc.centroid()

    const intersectionPoint = getIntersectionPoint(posA[0], posA[1], posB[0], posB[1], 0, 0, chartRadius)

    // posC: label position
    const posC = [...posB]
    posC[0] = posC[0] + barLabelOffsetBetweenBreakpoint * (isRightSide ? 1 : -1)

    let maxLabelWidth = width / 2 - Math.abs(posB[0])
    let labelY = posC[1] - labelContentMaxHeight / 2

    if (hasNarrowArea) {
      if (endIndex - startIndex + 1 > 5) {
        posC[0] = (originOuterRadius + barLabelOffsetBetweenBreakpoint) * (isRightSide ? 1 : -1)
        maxLabelWidth = width / 2 - Math.abs(posC[0])
      }
    } else {
      // to avoid the overlap at the bottom or top of the pie chart body
      if (chartBottomNarrowArea[0] < midAngle && midAngle < chartBottomNarrowArea[1]) {
        labelY += baseMargin / 5
      } else if (chartTopNarrowArea[0] > midAngle || midAngle > chartTopNarrowArea[1]) {
        labelY -= baseMargin / 5
      }
    }

    const labelX = isRightSide
      ? posC[0] - overlapBetweenPolylineAndLabel
      : posC[0] - maxLabelWidth + overlapBetweenPolylineAndLabel
    const polylinePoints = `${intersectionPoint.join(',')} ${posB.join(',')} ${posC.join(',')}`
    const title = v.data[props.options.x] || ''
    const textAligned = isRightSide ? 'left' : 'right'

    g
      .append('foreignObject')
      .attr('x', labelX)
      .attr('y', labelY)
      .attr('width', maxLabelWidth)
      .attr('height', labelContentMaxHeight)
      .append('xhtml:div')
      .attr('class', 'label-container')
      .style('display', 'flex')
      .style('text-align', textAligned).html(`
          <div class="label-title" style="overflow-wrap: break-word;">${title}</div>
          <div class="label-value">
            ${(v.data[props.options.y] * 100).toFixed(props.options.digit || 2)}${props.options.unit || '%'}
          </div>
        `)

    g.append('polyline')
      .attr('points', polylinePoints)
      .attr('class', 'label-line')
      .attr('stroke', 'black')
      .attr('fill', 'none')
      .attr('stroke-width', 0.5)
      .attr('opacity', 0.6)
  })

  if (hasNarrowArea && isExceedBoundary) {
    const offsetHeight = Math.max(leftLabelHeight, rightInitialLableHeight) - labelMaxHeight
    g.attr('transform', `translate(${width / 2},${height / 2 + offsetHeight})`)
  }
}

watch(
  () => [props.data, props.options],
  () => {
    renderChart()
  },
)

onMounted(() => {
  renderChart()
})
</script>
