<style>
.board .line .cell {
  align-items: flex-start;
}
.hidden {
  display: none;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
.row
  h1(style="margin-right: 0") {{ t[$root.screen.path] }}
  badge(:count="activeWorkflows.length")
subtitle(style="font-style: italic; margin-top: -5px;") {{ t['client-edition-workflows-subtitle'] }}
block.expand
  spreadsheet-simple.stripped.expand(:data="activeWorkflows" :options="{ columns: ['name', 'steps', 'usage', 'actions'], sort: ['name'], editable: false }")
    template(v-slot:cell-steps="s")
      div {{ s.line.actions?.v().filter().length }} {{ t.steps }}
    template(v-slot:cell-usage="s")
      div {{ getPressesCount(s.line.id) }}
    template(v-slot:cell-actions="s")
      .row
        button.ghost(:tt="t['edit']" @click="$router.push({ path: $root.appath + 'workflow', query: { id: s.line.id, action_id: s.line.actions?.v().first().id } })")
          svg-icon(name="pt-icon-edit")
        button.ghost(:tt="t['duplicate']" @click="duplicateWorkflow(s.line.id)")
          svg-icon(name="ic_file_copy")
        button.ghost(:tt="t['export']" @click="exportWorkflow(s.line.id)")
          svg-icon(name="pt-icon-export")
        button.ghost(:tt="t['delete']" @click="setConfirmRemoveId(s.line.id)" v-if="isDeletable(s.line.id)")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(style="margin-right: 8px" @click="createWorkflow") {{ t['create_workflow'] }}
    span
      button.primary(@click="triggerFileInput") {{ t['import_workflow'] }}
        input.hidden(type="file" ref="fileInput" accept="application/json" @change="importWorkflow")

  popup(:show="confirmRemoveId")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      | {{t["text_remove"]}}
    template(v-slot:action)
      button.secondary-action(@click="setConfirmRemoveId(null)") {{t['confirmation_btn_close']}}
      button.main-action(@click="deleteWorkflow") {{t["confirmation_btn_delete"]}}
</template>

<script>
import { ref } from 'vue'
import { readJSONFile } from '../../builder/lib/assetManagement'
import { useWorkflows } from '../composables/useWorkflows'
import { usePresses } from '../composables/usePresses'
import { useProgress } from '../composables/useProgress'
import { generateUniqueName, generateUniqueNameWhenDuplicateWorkflow } from '../utils/generateUniqueName'
import Popup from '../../../components/ui/popup.vue'

export const additions = {
  icon: 'ic_settings',
  components: { Popup },
}
export default {
  setup() {
    const { workflows, loaded: wfLoaded, initialized } = useWorkflows()
    const { presses, loaded: pLoaded } = usePresses()
    const { progress } = useProgress([wfLoaded, pLoaded])

    const confirmRemoveId = ref(null)

    return {
      progress,
      initialized,
      workflows,
      presses,
      confirmRemoveId,
    }
  },
  computed: {
    isLoading() {
      return this.progress !== 1 && !this.initialized
    },
    activeWorkflows() {
      return this.workflows.filter(w => w && !w.disabled)
    },
    activePress() {
      return this.presses.filter(p => !p.disabled)
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    setConfirmRemoveId(id) {
      this.confirmRemoveId = id
    },
    getPressesCount(id) {
      return this.activePress.filter(d => d.workflow_id === id).length
    },
    isDeletable(id) {
      // Avoid displaying delete button until presses are loaded
      if (this.activePress.length === 0) {
        return false
      }

      return this.getPressesCount(id) === 0
    },
    async createWorkflow() {
      const id = +(this.workflows || {}).v().map('id').max() + 1 || 0

      const existingNames = this.workflows.map(w => w.name)
      const name = generateUniqueName('Workflow', existingNames)

      await set(`data.workflows.${id}`, { ...this.empty_workflow, id, name })

      await this.$router.push({ path: $root.appath + 'workflow', query: { id, action_id: 1 } })
    },

    async duplicateWorkflow(workflowId) {
      // TODO move to workflow.vue
      const {
        newWorkflowId: id,
        newWorkflowName,
        baseWorkflow,
      } = generateUniqueNameWhenDuplicateWorkflow(this.workflows || [], workflowId)
      await set('data.workflows.' + id, { ...baseWorkflow, name: newWorkflowName, id })
      await this.$router.push($root.appath + 'workflow?id=' + id)
    },

    async deleteWorkflow() {
      update_query({ id: null })
      await set('data.workflows.' + this.confirmRemoveId + '.disabled', true)
      this.confirmRemoveId = null

      $root.toast({ description: $root.t.workflow_delete_success, type: 'success', timeout: 5000 })
    },

    async exportWorkflow(workflowId) {
      const workflow = this.workflows.find(w => w.id === workflowId)

      if (!workflow) {
        return
      }

      JSON.stringify(workflow).download(`${$root.project}-${workflow.name}.json`)
      $root.toast({ description: $root.t.workflow_download_success, type: 'success', timeout: 5000 })
    },

    async importWorkflow(event) {
      const workflowUpload = await readJSONFile(event)

      if (!workflowUpload) {
        $root.toast({ description: $root.t.workflow_format_error, type: 'error', timeout: 5000 })
      }

      // TODO: Validate input file schema + infer id from api
      const id = +(this.workflows || {}).v().map('id').max() + 1 || 0
      const newWorkflow = await set('data.workflows.' + id, {
        id,
        name: workflowUpload.name,
        actions: workflowUpload.actions,
      })

      if (!newWorkflow.id) {
        $root.toast({ description: $root.t.workflow_import_error, type: 'error', timeout: 5000 })
      }

      $root.toast({ description: $root.t.workflow_import_success, type: 'success', timeout: 5000 })
      await this.$router.push({ path: $root.appath + 'workflow?id=' + id, query: { id, action_id: 1 } })
    },
  },
}
</script>
