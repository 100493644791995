<template>
  <builder-block :data="props.data || gauge.props.data" :options="props.options" :context="props.context">
    <div class="block-wrapper block">
      <div class="gauge-container relative flex flex-col">
        <div class="h-full w-full">
          <div class="gauge-bar-wrapper flex w-full">
            <div
              class="gauge-side-label min-label first-gauge"
              :style="{ position: 'relative', top: firstGaugeLabelHeight + 'px' }"
            >
              0{{ props.options.unit }}
            </div>
            <div class="w-full">
              <div ref="firstGaugeLabel" class="gauge-indicator first-gauge relative">
                <div class="relative h-full w-full">
                  <div
                    class="gauge-pointer-label first-gauge"
                    :style="{
                      position: 'relative',
                      left: `${Math.min(75, Math.max(25, resolvedData[0].value * 100 || 0))}%`,
                      display: 'flex',
                      'align-items': 'center',
                    }"
                  >
                    <div
                      :style="{
                        transform: `${getTranslateX(resolvedData[0].value)}`,
                        width: '50%',
                        'text-align': `${getAlignItem(resolvedData[0].value)}`,
                      }"
                    >
                      <span>{{ translate(props.options.primaryIndicatorLabel?.trim() || resolvedData[0]?.key) }}</span>
                      <span>{{ ' ' + formatNumber(resolvedData[0]?.value) }}</span>
                    </div>
                  </div>
                  <div
                    class="gauge-pointer relative"
                    :style="{ left: `calc(${resolvedData[0].value * 100 || 0}% - 7px)`, top: '10px' }"
                  >
                    <!-- Adjusts 'left' dynamically, with a -7px offset for visual alignment -->
                    <svg
                      class="gauge-pointer origin-middle rotate-180"
                      width="14"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                      v-html="pointerSVG"
                    ></svg>
                  </div>
                </div>
              </div>
              <div class="gauge-bar first-gauge"></div>
              <div v-if="!options.dualBars && resolvedData.length > 1" class="gauge-indicator second-gauge relative">
                <div class="relative h-full w-full">
                  <div
                    class="gauge-pointer relative"
                    :style="{ left: `calc(${resolvedData[1].value * 100 || 0}% - 7px)`, bottom: '10px' }"
                  >
                    <!-- Adjusts 'left' dynamically, with a -7px offset for visual alignment -->
                    <svg
                      class="gauge-pointer origin-middle"
                      width="14"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                      v-html="pointerSVG"
                    ></svg>
                  </div>
                  <div
                    class="gauge-pointer-label second-gauge"
                    :style="{
                      position: 'relative',
                      left: `${Math.min(75, Math.max(25, resolvedData[1].value * 100 || 0))}%`,
                      display: 'flex',
                      'align-items': 'center',
                    }"
                  >
                    <div
                      :style="{
                        transform: `${getTranslateX(resolvedData[1].value)}`,
                        width: '50%',
                        'text-align': `${getAlignItem(resolvedData[1].value)}`,
                      }"
                    >
                      <span>
                        {{ translate(props.options.comparisonIndicatorLabel?.trim() || resolvedData[1]?.key) }}
                      </span>
                      <span>{{ ' ' + formatNumber(resolvedData[1]?.value) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="gauge-side-label max-label first-gauge"
              :style="{ position: 'relative', top: firstGaugeLabelHeight + 'px' }"
            >
              {{ props.options.unit === '%' ? '100%' : '1' }}
            </div>
          </div>
          <div v-if="props.options.dualBars && resolvedData.length > 1" class="gauge-bar-wrapper flex w-full">
            <div class="gauge-side-label min-label second-gauge">0{{ props.options.unit }}</div>
            <div class="w-full">
              <div class="gauge-bar second-gauge"></div>
              <div class="gauge-indicator second-gauge relative">
                <div class="relative h-full w-full">
                  <div
                    class="gauge-pointer relative"
                    :style="{ left: `calc(${resolvedData[1]?.value * 100 || 0}% - 7px)`, bottom: '10px' }"
                  >
                    <!-- Adjusts 'left' dynamically, with a -7px offset for visual alignment -->
                    <svg
                      class="gauge-pointer origin-middle"
                      width="14"
                      height="18"
                      xmlns="http://www.w3.org/2000/svg"
                      v-html="pointerSVG"
                    ></svg>
                  </div>
                  <div
                    class="gauge-pointer-label second-gauge"
                    :style="{
                      position: 'relative',
                      left: `${Math.min(75, Math.max(25, resolvedData[1].value * 100 || 0))}%`,
                      display: 'flex',
                      'align-items': 'center',
                    }"
                  >
                    <div
                      :style="{
                        transform: `${getTranslateX(resolvedData[1].value)}`,
                        width: '50%',
                        'text-align': `${getAlignItem(resolvedData[1].value)}`,
                      }"
                    >
                      <span>
                        {{ translate(props.options.comparisonIndicatorLabel?.trim() || resolvedData[1]?.key) }}
                      </span>
                      <span>{{ ' ' + formatNumber(resolvedData[1]?.value) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="gauge-side-label max-label second-gauge">{{ props.options.unit === '%' ? '100%' : '1' }}</div>
          </div>
        </div>
      </div>
    </div>
  </builder-block>
</template>

<script setup>
import { computed, defineProps, ref, onMounted, onUnmounted } from 'vue'
import { gauge } from './stories'
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
const { translate } = useTranslations(props)

function formatNumber(value) {
  if (props.options.unit === '%') return `${(value * 100).toFixed(props.options.digit)}${props.options.unit}`
  return `${value.toFixed(props.options.digit)}${props.options.unit}`
}

/*
 * The `getTranslateX` and `getAlignItem` functions adjust the position and alignment of labels
 * to ensure they remain readable when placed near the edges of the container.
 *
 * - When the "stick label" option is enabled, labels are centered on the pointer. Hence, the offset calculation is adjusted accordingly
 * - When "stick label" is disabled:
 *   - If the value is below 25%, the label shifts fully to the left and translate the start of the label container above the value 0 to prevent overflow
 *   - If the value is between 25% and 75%, it remains centered.
 *   - If the value exceeds 75%, the label shifts fully to the right and translate the end of the label container above the value 100 to prevent overflow
 */
const getTranslateX = value => {
  if (!props.options.stickLabel || (value >= 0.25 && value <= 0.75)) return 'translateX(-50%)'

  const offset = value < 0.25 ? 100 - value * 200 : (100 - value * 100) * 2
  return `translateX(-${offset}%)`
}

const getAlignItem = value => {
  if (!props.options.stickLabel) {
    if (value < 0.25) return 'start'
    if (value > 0.75) return 'end'
  }
  return 'center'
}

const resolvedData = computed(() => {
  if (!Array.isArray(props.data)) return []
  return props.data.map(item => {
    return {
      key: item.key ?? 'unknown',
      value: item.value ?? 0,
    }
  })
})

/*
 * These variables are used to determine the height of the first label text in order to move the sides labels at the same level of the bar
 * Moving the side labels at the same level of the bar in the code will break the position of the pointer and his label
 * unless you add the length of the left side label to the pointer
 */
const firstGaugeLabel = ref(null)
const firstGaugeLabelHeight = ref(0)
let resizeObserver = null

onMounted(async () => {
  if (firstGaugeLabel.value) {
    firstGaugeLabelHeight.value = firstGaugeLabel.value.offsetHeight

    resizeObserver = new ResizeObserver(() => {
      firstGaugeLabelHeight.value = firstGaugeLabel.value.offsetHeight
    })

    resizeObserver.observe(firstGaugeLabel.value)
  }
})

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})

const pointerSVG = computed(() => {
  switch (props.options.pointerVariant) {
    case 'rounded':
      return `
        <line class="gauge-pointer-line stroke-[3] rounded-full" x1="7" y1="7" x2="7" y2="18"></line>
        <circle class="gauge-pointer-shape" cx="7" cy="5" r="5"></circle>
      `
    case 'triangle':
      return `
        <polygon class="gauge-pointer-shape" points="7,0 14,14 0,14"></polygon>
      `
    default:
      return `
         <line class="gauge-pointer-line stroke-[3]" x1="7" y1="7" x2="7" y2="18"></line>
         <path class="gauge-pointer-shape" d="M0,7 L14,7 L7,0 L0,7 Z"></path>
      `
  }
})
</script>

<script>
export default {
  api: {
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 1,
      attrs: {
        type: 'number',
      },
    },
    dualBars: {
      label: 'Dual Bars',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    pointerVariant: {
      label: 'Pointer variant',
      default: () => 'arrow',
      select: () => ['arrow', 'triangle', 'rounded'],
    },
    primaryIndicatorLabel: {
      label: 'Main Pointer Label',
      dataList: ({ context }) => {
        return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
      },
    },
    comparisonIndicatorLabel: {
      label: 'Comparison Pointer Label',
      dataList: ({ context }) => {
        return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
      },
    },
    stickLabel: {
      label: 'Stick label to pointer',
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
  styles: {
    '.gauge-container': {
      name: 'Gauge Container',
      css: ``,
    },
    '.gauge-container .gauge-indicator': {
      name: 'indicator',
      css: `fill:black;
line {stroke: black;}
`,
    },
    '.gauge-container .gauge-pointer-label': {
      name: 'indicator label',
      css: `&.first-gauge { top:10px }
&.second-gauge { bottom:10px;}`,
    },
    '.gauge-container .gauge-bar': {
      name: 'Bar',
      css: `border: 1px solid black;
background: linear-gradient(to right, var(--palette-1), var(--palette-2));
margin-top: 0.25rem;
margin-bottom: 0.25rem;
height: 1.5rem;`,
    },
    '.gauge-container .gauge-side-label': {
      name: 'Side labels',
      css: `&.min-label{ padding-right:5px;}
&.max-label{ margin-left: 5px;}
transform: translateY(7px);
`,
    },
  },
  story: gauge,
}
</script>
