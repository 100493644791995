<template>
  <builder-block v-bind="props">
    <div class="error-message text-red-500" v-if="!indicator">
      <p>Fund indicator data is missing</p>
    </div>
    <div class="sdg-container" v-else>
      <div class="indicator-content flex items-stretch justify-center">
        <div class="sdg-logo flex w-24 items-center justify-center" v-if="indicator && props.options.showSdgLogo">
          <img v-if="indicator.logo" :src="resolveSdgImagePath(indicator.logo)" alt="SDG Icon" />
        </div>

        <div class="sdg-bar-container flex flex-1">
          <builder-bar v-bind="{ ...props, data: resolvedIndicators, options: sdgIndicatorOptions }"></builder-bar>
        </div>
      </div>

      <div class="coverage-rate">
        <template v-if="resolvedIndicators.length === 2">
          <p>
            {{ translate('indicator_funds_univers_coverage') || 'indicator_funds_univers_coverage' }} :
            {{ Math.round(resolvedIndicators[0].coverage * 100) }}% /
            {{ Math.round(resolvedIndicators[1].coverage * 100) }}%
          </p>
        </template>
        <template v-else>
          <p v-for="indicator in resolvedIndicators" :key="indicator.indicator">
            {{ translate('indicator_coverage_rage') || 'indicator_coverage_rage' }} :
            {{ Math.round(indicator.coverage * 100) }}%
          </p>
        </template>
      </div>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
import { computed, defineProps, ref } from 'vue'
import { sdgIndicator } from '../composables/builderOptions'
import useTranslations from '../composables/translations'
import { sdgIndicatorStory } from './stories'

type Indicator = {
  key: string
  idNumber: string
  indicator: string
  value: number
  coverage: number
  unit: string
  logo: string
  title: string
  id: string
}

const props = defineProps(['data', 'options', 'context'])

const { translate } = useTranslations(props)

const sdgIndicatorOptions = computed(() => {
  const options = {
    showSdgLogo: true,
    version: 'v2',
    x: 'key',
    y: 'value',
    category: 'category',
    legend: true,
    tooltip: false,
    label: false,
    legendPosition: 'bottom',
    legendShape: 'square',
    legendOrientation: 'horizontal',
    // "showValues": "evolution",
    ...props.options,

    // hide default value from builder-chart
    title: '',
    subtitle: '',
    disclaimer: '',
    variant: 'sdg-indicator',
  }

  return options
})

const resolveSdgImagePath = (logo: string) => {
  if (!logo) return ''
  return new URL(`../../../assets/images/sdg-indicators/${logo}`, import.meta.url).href
}

// assume all indicators is the same category
const indicator = computed(() => {
  return resolvedIndicators.value?.length > 0 ? resolvedIndicators.value[0] : null
})

const indicatorMaxValue = ref(0)

const resolvedIndicators = computed(() => {
  if (!props.data) return []
  const isCombinedSdgs = Array.isArray(props.data)
  const data: Indicator[] = isCombinedSdgs ? props.data : [props.data]

  const resolvedData = data.map(item => {
    if (indicatorMaxValue.value < item.value) {
      indicatorMaxValue.value = item.value
    }

    const [id = '', title = ''] = item.indicator.split('-')

    return {
      category: isCombinedSdgs ? item.key : 'fund',
      key: 'key',
      value: item.value,
      unit: item.unit,
      indicator: item.indicator,
      coverage: item.coverage,
      logo: `${id.toLowerCase()}.png`,
      title,
    }
  })

  return resolvedData
})
</script>

<script lang="ts">
export default {
  api: sdgIndicator,
  styles: {
    'sdg-container': `.x-axis,.y-axis,.x-grid,.y-grid {display: none;}; .builder-block {padding: 0px;};
.bar-bar{
  rect.above {  rx:3; ry:3;};
  rect.bottom { shape-rendering: crispEdges;};
  .bottom-1, .group-1 { transform: translate(-1px,0px) };
};
    `,
    'sdg-logo': 'width: 100px;',
    'coverage-rate': `text-align: center; font-size: 14px; padding-top: 8px;`,
    '.sdg-container .chart-container': `height: 160px;`,
    '.sdg-container  .bar-label': `font-size:12px; fill: black;`,
    '.nx-chart-legend': `display: flex; justify-content: center; flex-direction: row; gap: 8px;`,
  },
  story: sdgIndicatorStory,
}
</script>
